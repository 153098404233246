  /* fallback */

  body {
    background: #ffffff !important;
  }

  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(/public/static/media/material-icons.d7e60f9d.woff2) format('woff2');
  }

  .material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: 'liga';
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
  }

  * {
      -webkit-animation: fadein 500ms;
              animation: fadein 500ms;
  }


  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }


  @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
